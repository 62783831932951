import Link from 'next/link';

import { Input } from '@/components/ui/input';

export function AuthForm({
  action,
  children,
  defaultEmail = '',
}: {
  action: any;
  children: React.ReactNode;
  defaultEmail?: string;
}) {
  return (
    <div className="w-[600px] flex flex-col items-center text-center">
      <form action={action} className="max-w-[600px]">
        <div className="rounded-lg bg-[#F7F7F7] p-8 space-y-6">
          <div className="space-y-2 text-left">
            <h1 className="text-3xl font-semibold tracking-tight">
              Sign up for free
            </h1>
            <p className="text-sm text-gray-500">
              We recommend using your work email — it keeps work and life
              separate.
            </p>
          </div>
          <div className="space-y-4">
            <div>
              <Input
                id="email"
                name="email"
                placeholder="Work email"
                type="email"
                autoCapitalize="none"
                autoComplete="email"
                autoCorrect="off"
                className="h-12"
                required
                autoFocus
                defaultValue={defaultEmail}
              />
            </div>
            {children}
          </div>
        </div>
      </form>
      <p className="text-xs text-gray-500 max-w-[524px]">
        Your name and photo are displayed to users who invite you to a workspace
        using your email. By continuing, you acknowledge that you understand and
        agree to the{' '}
        <Link
          href="/terms"
          className="underline underline-offset-4 hover:text-gray-800"
        >
          Terms & Conditions
        </Link>{' '}
        and{' '}
        <Link
          href="/privacy"
          className="underline underline-offset-4 hover:text-gray-800"
        >
          Privacy Policy
        </Link>
        .
      </p>
    </div>
  );
}
