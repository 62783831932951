'use client';

import { useRouter } from 'next/navigation';
import { useActionState, useEffect, useState } from 'react';
import { toast } from 'sonner';

import { AuthForm } from '@/components/custom/auth-form';
import { SubmitButton } from '@/components/custom/submit-button';
import { Button } from '@/components/ui/button';

import { login, LoginActionState } from '../actions';
import { Header } from '@/components/custom/dashbaoard/header';
import { trackEvent } from '@/utils/analytics';

export default function Page() {
  const router = useRouter();

  const [email, setEmail] = useState('');
  const [isSuccessful, setIsSuccessful] = useState(false);

  const [state, formAction] = useActionState<LoginActionState, FormData>(
    login,
    {
      status: 'idle',
    }
  );

  useEffect(() => {
    const { status } = state;
    if (status !== 'idle' && status !== 'in_progress') {
      trackEvent(`login result: ${state.status} `);
    }
    if (status === 'failed') {
      toast.error('Invalid credentials!');
    } else if (status === 'invalid_data') {
      toast.error('Failed validating your submission!');
    } else if (status === 'success') {
      setIsSuccessful(true);
      router.push(state.url);
    }
  }, [state.status, state, router]);

  const handleSubmit = (formData: FormData) => {
    trackEvent('login initiated');

    setEmail(formData.get('email') as string);
    formAction(formData);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="grow flex items-center justify-center ">
        <AuthForm action={handleSubmit} defaultEmail={email}>
          <SubmitButton
            isSuccessful={isSuccessful}
            className="w-full h-12 bg-[#B6E9F2] text-black hover:bg-[#a3e0eb] font-medium"
          >
            Continue with email
          </SubmitButton>
          {/* <Button className="w-full h-12 bg-[#B6E9F2] text-black hover:bg-[#a3e0eb] font-medium">
            Continue with email
          </Button> */}
        </AuthForm>
      </main>
    </div>
  );
  // return (
  //   <div className="flex h-dvh w-screen items-start pt-12 md:pt-0 md:items-center justify-center bg-background">
  //     <div className="w-full max-w-md overflow-hidden rounded-2xl flex flex-col gap-6">
  //       <div className="flex flex-col items-center justify-center gap-2 px-4 text-center sm:px-16">
  //         <h3 className="text-xl font-semibold dark:text-zinc-50">Sign In</h3>
  //         <p className="text-sm text-gray-500 dark:text-zinc-400">
  //           Use your email to sign in
  //         </p>
  //       </div>
  //       <AuthForm action={handleSubmit} defaultEmail={email}>
  //         <SubmitButton isSuccessful={isSuccessful}>Sign in</SubmitButton>
  //       </AuthForm>
  //     </div>
  //   </div>
  // );
}
