import posthog from 'posthog-js';

type trackEvent = (
  eventName: string,
  properties?: {
    [x: string]: any;
  } | null
) => undefined;

export const trackEvent: trackEvent = (eventName, properties) => {
  posthog.capture(eventName, properties);
};
