import Image from 'next/image';
import { Button } from '@/components/ui/button';
import { Home } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { signOut } from '@/app/(auth)/actions';
import Link from 'next/link';

interface ProjectHeaderProps {
  showBackToHome?: boolean;
  showProjectSelector?: boolean;
  showSignOut?: boolean;
}

export function Header({
  showBackToHome = false,
  showProjectSelector = false,
  showSignOut = false,
}: ProjectHeaderProps) {
  return (
    <div>
      <header className="border-b bg-white">
        <div className="mx-auto flex h-16 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
          <div className="flex items-center gap-2">
            <Image
              src="/images/lio.svg"
              alt="Lio"
              width={32}
              height={32}
              className="h-8 w-8"
            />
            <span className="text-sm">By Aqmen</span>
          </div>
          {showSignOut && (
            <form action={signOut}>
              <Button type="submit" variant="outline" size="sm">
                Sign Out
              </Button>
            </form>
          )}
        </div>
      </header>

      {(showBackToHome || showProjectSelector) && (
        <div className="border-b bg-gray-50">
          <div className="mx-auto flex h-14 max-w-7xl items-center gap-3 px-4 sm:px-6 lg:px-8">
            {showBackToHome && (
              <Link href={'/'}>
                <Home className="size-5 text-gray-500" />
              </Link>
            )}
            {showProjectSelector && (
              <Select defaultValue="peps-food">
                <SelectTrigger className="w-[180px] border-0 bg-transparent p-0 hover:bg-transparent focus:ring-0">
                  <SelectValue placeholder="Select project">
                    Peps food - EMEA
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="peps-food">Peps food - EMEA</SelectItem>
                  <SelectItem value="other">Other projects</SelectItem>
                </SelectContent>
              </Select>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
